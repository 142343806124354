import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';
// Loader Components
import showLoader from '@components/loader';

export const getMainCategory = createAsyncThunk('mainCategorySlice/getMainCategory', async () => {
    try {
        let response = await axios.get('/main-category', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getMainCategoryById = createAsyncThunk('mainCategorySlice/getMainCategoryById', async (id) => {
    try {
        let response = await axios.get('/main-category/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getMainCategoryOption = createAsyncThunk('mainCategorySlice/getMainCategoryOption', async (data) => {
    const query = queryString.stringify(data);
    try {
        let response = await axios.get('/main-category/options?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getListingMainCategoryOption = createAsyncThunk('mainCategorySlice/getListingMainCategoryOption', async () => {
    try {
        let response = await axios.get('/listing/main-category/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createMainCategory = createAsyncThunk('mainCategorySlice/createMainCategory', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/main-category', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Sub Category added successfully.');
        dispatch(getMainCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateMainCategory = createAsyncThunk('mainCategorySlice/updateMainCategory', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/main-category/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Sub Category updated succesfully.');
        dispatch(getMainCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteMainCategory = createAsyncThunk('mainCategorySlice/deleteMainCategory', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/main-category/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Sub Category deleted successfully.');
        dispatch(getMainCategory());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateMainCategoryStatus = createAsyncThunk('mainCategorySlice/updateMainCategoryStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/main-category/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getMainCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const mainCategorySlice = createSlice({
    name: 'mainCategorySlice',
    initialState: {
        data: [],
        option: [],
        mainCategoryOption: []
    },
    extraReducers: (builder) => {
        builder.addCase(getMainCategory.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getMainCategoryOption.fulfilled, (state, action) => {
            state.option = action?.payload;
            return;
        });
        builder.addCase(getListingMainCategoryOption.fulfilled, (state, action) => {
            state.mainCategoryOption = action?.payload;
            return;
        });
    }
});

export default mainCategorySlice.reducer;
