import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';
// Loader Components
import showLoader from '@components/loader';

export const getBuyer = createAsyncThunk('buyerSlice/getBuyer', async () => {
    try {
        let response = await axios.get('/buyer', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getBuyerOptions = createAsyncThunk('buyerSlice/getBuyerOptions', async () => {
    try {
        let response = await axios.get('/buyer/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getEMDOutOptions = createAsyncThunk('buyerSlice/getEMDOutOptions', async () => {
    try {
        let response = await axios.get('/emd-out/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getBuyerByStatus = createAsyncThunk('buyerSlice/getBuyerByStatus', async (id) => {
    try {
        showLoader(true);
        let response = await axios.get('/buyer?status=' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getBuyerByRequestStatus = createAsyncThunk('buyerSlice/getBuyerByRequestStatus', async (id) => {
    try {
        let response = await axios.get('/buyer-request?status=' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getBuyerById = createAsyncThunk('buyerSlice/getBuyerById', async (id) => {
    try {
        let response = await axios.get('/buyer/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getBuyerLimit = createAsyncThunk('buyerSlice/getBuyerLimit', async (id) => {
    try {
        showLoader(true);
        let response = await axios.get('/buyer/buyer-limit/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getBuyerBuyingLimit = createAsyncThunk('buyerSlice/getBuyerBuyingLimit', async (id) => {
    try {
        let response = await axios.get('/buyer-limit/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getVehicleOption = createAsyncThunk('buyerSlice/getVehicleOption', async () => {
    try {
        let response = await axios.get('/vehicle/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createBuyer = createAsyncThunk('buyerSlice/createBuyer', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/buyer', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Buyer added successfully.');
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createBuyerBuyingLimit = createAsyncThunk('buyerSlice/createBuyerBuyingLimit', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/buyer-limit', data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Buyer added successfully.');
        dispatch(getBuyerBuyingLimit(data?.id));
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createBuyerEMD = createAsyncThunk('buyerSlice/createBuyerEMD', async (data) => {
    try {
        showLoader(true);
        let response = await axios.put('/buyer/emd-charges/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'EMD added successfully.');
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const changePassword = createAsyncThunk('buyerSlice/changePassword', async (data) => {
    try {
        showLoader(true);
        let response = await axios.put('/buyer/change-password/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'EMD added successfully.');
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateBuyer = createAsyncThunk('buyerSlice/updateBuyer', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/buyer/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Buyer updated succesfully.');
        dispatch(getBuyer());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteBuyer = createAsyncThunk('buyerSlice/deleteBuyer', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/buyer/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Buyer deleted successfully.');
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteBuyerLimit = createAsyncThunk('buyerSlice/deleteBuyerLimit', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.delete('/buyer-limit/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Buyer deleted successfully.');
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateBuyerApprovalStatus = createAsyncThunk('buyerSlice/updateBuyerApprovalStatus', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/buyer/approval/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateBuyerStatus = createAsyncThunk('buyerSlice/updateBuyerStatus', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/buyer/status/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateBuyerRequestStatus = createAsyncThunk('buyerSlice/updateBuyerRequestStatus', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/buyer-request/status/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateBuyerUserStatus = createAsyncThunk('buyerSlice/updateBuyerUserStatus', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/buyer/user/status/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getBuyerByStatus(1));
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getAMCBuyerById = createAsyncThunk('buyerSlice/getAMCBuyerById', async (id) => {
    try {
        let response = await axios.get('/buyer/amc/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getAccountHistoryBuyerById = createAsyncThunk('buyerSlice/getAccountHistoryBuyerById', async (id) => {
    try {
        let response = await axios.get('/buyer/account-history/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getBuyerNotAssignCommunity = createAsyncThunk('buyerSlice/getBuyerNotAssignCommunity', async (id) => {
    try {
        showLoader(true);
        let response = await axios.get('/buyer/not-assign-permission/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getBuyerAssignCommunity = createAsyncThunk('buyerSlice/getBuyerAssignCommunity', async (id) => {
    try {
        showLoader(true);
        let response = await axios.get('/buyer/assign-permission/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createBuyerAssignCommunity = createAsyncThunk('buyerSlice/createBuyerAssignCommunity', async (data) => {
    try {
        showLoader(true);
        let response = await axios.post('/buyer/assign-permission', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Buyer added successfully.');
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getBuyerDocumentHistory = createAsyncThunk('buyerSlice/getBuyerDocumentHistory', async (id) => {
    try {
        showLoader(true);
        let response = await axios.get('/buyer/document-status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const changeDocumentStatus = createAsyncThunk('buyerSlice/changeDocumentStatus', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/buyer/document-status/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status Change successfully.');
        dispatch(getBuyerDocumentHistory(data?.id));
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const buyerSlice = createSlice({
    name: 'buyerSlice',
    initialState: {
        data: [],
        option: [],
        vehicleOption: [],
        buyer: [],
        buyerStatus: [],
        buyerRequestStatus: [],
        buyingLimit: [],
        emdOption: [],
        buyerLimit: {},
        amcBuyer: [],
        accountHistoryBuyer: [],
        notAssignCommunity: [],
        assignCommunity: [],
        documentHistory: {}
    },
    reducers: {
        removeBuyerData: (state) => {
            state.buyer = [];
        },
        removeBuyerRequestData: (state) => {
            state.buyerRequestStatus = [];
        },
        removeSelectedStatusData: (state) => {
            state.buyerStatus = [];
        },
        removeBuyerLimitData: (state) => {
            state.buyerLimit = {};
        },
        removeBuyerDocumentHistoryData: (state) => {
            state.documentHistory = {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getBuyer.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getBuyerOptions.fulfilled, (state, action) => {
            state.option = action?.payload;
            return;
        });
        builder.addCase(getVehicleOption.fulfilled, (state, action) => {
            state.vehicleOption = action?.payload;
            return;
        });
        builder.addCase(getBuyerById.fulfilled, (state, action) => {
            state.buyer = action?.payload;
            return;
        });
        builder.addCase(getAMCBuyerById.fulfilled, (state, action) => {
            state.amcBuyer = action?.payload;
            return;
        });
        builder.addCase(getAccountHistoryBuyerById.fulfilled, (state, action) => {
            state.accountHistoryBuyer = action?.payload;
            return;
        });
        builder.addCase(getBuyerLimit.fulfilled, (state, action) => {
            state.buyerLimit = action?.payload;
            return;
        });
        builder.addCase(getBuyerNotAssignCommunity.fulfilled, (state, action) => {
            state.notAssignCommunity = action?.payload;
            return;
        });
        builder.addCase(getBuyerAssignCommunity.fulfilled, (state, action) => {
            state.assignCommunity = action?.payload;
            return;
        });
        builder.addCase(getBuyerByStatus.fulfilled, (state, action) => {
            state.buyerStatus = action?.payload;
            return;
        });
        builder.addCase(getBuyerByRequestStatus.fulfilled, (state, action) => {
            state.buyerRequestStatus = action?.payload;
            return;
        });
        builder.addCase(getBuyerBuyingLimit.fulfilled, (state, action) => {
            state.buyingLimit = action?.payload;
            return;
        });
        builder.addCase(getEMDOutOptions.fulfilled, (state, action) => {
            state.emdOption = action?.payload;
            return;
        });
        builder.addCase(getBuyerDocumentHistory.fulfilled, (state, action) => {
            state.documentHistory = action?.payload;
            return;
        });
    }
});

export const { removeBuyerData, removeSelectedStatusData, removeBuyerRequestData, removeBuyerLimitData, removeBuyerDocumentHistoryData } =
    buyerSlice.actions;

export default buyerSlice.reducer;
