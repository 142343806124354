// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '../../../service/axios';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from 'query-string';

export const getBuyerStatus = createAsyncThunk('adminDashboardSliceData/getBuyerStatus', async (data) => {
    const query = queryString.stringify(data);

    try {
        // showLoader(true);
        let response = await axios.get('/dashboard/buyer/status?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // showLoader(false);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getListingStatus = createAsyncThunk('adminDashboardSliceData/getListingStatus', async (data) => {
    const query = queryString.stringify(data);

    try {
        // showLoader(true);
        let response = await axios.get('/dashboard/listing/status?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // showLoader(false);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getBuyerRequest = createAsyncThunk('adminDashboardSliceData/getBuyerRequest', async (data) => {
    const query = queryString.stringify(data);

    try {
        // showLoader(true);
        let response = await axios.get('/dashboard/buyer-request?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // showLoader(false);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getSupport = createAsyncThunk('adminDashboardSliceData/getSupport', async (data) => {
    const query = queryString.stringify(data);

    try {
        // showLoader(true);
        let response = await axios.get('/dashboard/support?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // showLoader(false);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getListing = createAsyncThunk('adminDashboardSliceData/getListing', async (data) => {
    const query = queryString.stringify(data);

    try {
        // showLoader(true);
        let response = await axios.get('/dashboard/listing?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // showLoader(false);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const adminDashboardSlice = createSlice({
    name: 'adminDashboardSliceData',
    initialState: {
        selectedBuyer: [],
        subscriber: {},
        buyerStatus: {},
        listingStatus: {},
        buyerPermission: {},
        buyerRequest: {},
        support: {},
        listing: []
    },
    extraReducers: (builder) => {
        builder.addCase(getBuyerStatus.fulfilled, (state, action) => {
            state.buyerStatus = action?.payload;
            return;
        });
        builder.addCase(getListingStatus.fulfilled, (state, action) => {
            state.listingStatus = action?.payload;
            return;
        });

        builder.addCase(getBuyerRequest.fulfilled, (state, action) => {
            state.buyerRequest = action?.payload;
            return;
        });
        builder.addCase(getSupport.fulfilled, (state, action) => {
            state.support = action?.payload;
            return;
        });
        builder.addCase(getListing.fulfilled, (state, action) => {
            state.listing = action?.payload;
            return;
        });
    }
});
export default adminDashboardSlice.reducer;
