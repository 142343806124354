// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getActiveClosed = createAsyncThunk('getAllActiveClosedData/getActiveClosed', async (data) => {
    try {
        let response = await axios.get('/HelpdeskTicket/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getHelpDeskRequest = createAsyncThunk('getAllActiveClosedData/getHelpDeskRequest', async (data) => {
    try {
        let response = await axios.get('/support-ticket?status=' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const createHelpDeskRequest = createAsyncThunk('getAllActiveClosedData/createHelpDeskRequest', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/support-ticket', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Ticket created successfully.');
        dispatch(getHelpDeskRequest(0));
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const updateHelpDeskRequest = createAsyncThunk('getAllActiveClosedData/updateHelpDeskRequest', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/support-ticket/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Ticket created successfully.');
        dispatch(getHelpDeskRequest(data?.status));
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const deleteHelpDeskRequest = createAsyncThunk('getAllActiveClosedData/deleteHelpDeskRequest', async (data, { dispatch }) => {
    try {
        let response = await axios.delete('/support-ticket/' + data?.deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Support Ticket deleted successfully.');
        await dispatch(getHelpDeskRequest(data?.status));
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const updateHelpReqStatus = createAsyncThunk('getAllActiveClosedData/updateHelpReqStatus', async (data) => {
    try {
        let response = await axios.put('/support-ticket/status/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Helpdesk Ticket update succesfully.');
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getHelpdeskRemarks = createAsyncThunk('getAllActiveClosedData/getHelpdeskRemarks', async (id) => {
    try {
        let response = await axios.get('/HelpdeskTicket/get-remarks/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getEmployees = createAsyncThunk('getAllActiveClosedData/getEmployees', async () => {
    try {
        let response = await axios.get('/Employee/all-employee', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateHelpRequestStatus = createAsyncThunk('getAllActiveClosedData/updateHelpRequestStatus', async (data) => {
    try {
        let response = await axios.put('/HelpdeskTicket/request-status/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Helpdesk Ticket update succesfully.');
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createNewRequest = createAsyncThunk('getAllActiveClosedData/createNewRequest', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/HelpdeskTicket', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'New Request added succesfully.');
        // await dispatch(getActiveClosed(0));
        // await dispatch(getActiveClosed(1));
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const createForwardRequest = createAsyncThunk('getAllActiveClosedData/createForwardRequest', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/HelpdeskTicket/forward', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Forward Request added succesfully.');

        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateNewRequest = createAsyncThunk('getAllActiveClosedData/updateNewRequest', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/HelpdeskTicket/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'New Request updated succesfully.');
        // await dispatch(getAllowance());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateActiveClosedStatus = createAsyncThunk('getAllActiveClosedData/updateActiveClosedStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/HelpdeskTicket/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || ' Status updated successfully !');
        // dispatch(getActiveClosed());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteActiveClosed = createAsyncThunk('getAllActiveClosedData/deleteActiveClosed', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/HelpdeskTicket/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'ActiveClosed deleted successfully.');
        // await dispatch(getAllowance());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const helpDeskSlice = createSlice({
    name: 'getAllActiveClosedData',

    initialState: {
        helpDeskRequest: [],
        activeClosed: [],
        employees: []
    },
    reducers: {
        removeActiveClosed: (state) => {
            state.activeClosed = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getActiveClosed.fulfilled, (state, action) => {
            state.activeClosed = action?.payload;
            return;
        });
        builder.addCase(getHelpDeskRequest.fulfilled, (state, action) => {
            state.helpDeskRequest = action?.payload;
            return;
        });
        builder.addCase(getEmployees.fulfilled, (state, action) => {
            state.employees = action?.payload;
            return;
        });
    }
});

export const { removeActiveClosed } = helpDeskSlice.actions;

export default helpDeskSlice.reducer;
