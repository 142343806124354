import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';
// Loader Components
import showLoader from '@components/loader';

export const getSeller = createAsyncThunk('sellerSlice/getSeller', async () => {
    try {
        let response = await axios.get('/seller', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getSellerOption = createAsyncThunk('sellerSlice/getSellerOption', async () => {
    try {
        let response = await axios.get('/seller/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createSeller = createAsyncThunk('sellerSlice/createSeller', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/seller', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Seller added successfully.');
        dispatch(getSeller());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateSeller = createAsyncThunk('sellerSlice/updateSeller', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/seller/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Seller updated succesfully.');
        dispatch(getSeller());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteSeller = createAsyncThunk('sellerSlice/deleteSeller', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/seller/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Seller deleted successfully.');
        dispatch(getSeller());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateSellerStatus = createAsyncThunk('sellerSlice/updateSellerStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/seller/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getSeller());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const sellerSlice = createSlice({
    name: 'sellerSlice',
    initialState: {
        data: [],
        option: [],
        sellerOption: []
    },
    extraReducers: (builder) => {
        builder.addCase(getSeller.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getSellerOption.fulfilled, (state, action) => {
            state.option = action?.payload;
            return;
        });
    }
});

export default sellerSlice.reducer;
