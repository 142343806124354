import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';
import queryString from 'query-string';
import { getMainCategoryListing } from '../../../ManageListing/Listing/store';

export const getAuctionClosingReportDownloadExcel = createAsyncThunk(
    'AuctionClosingReportSlice/getAuctionClosingReportDownloadExcel',
    async (data, { dispatch }) => {
        const query = queryString.stringify(data?.acrDownloaded);

        try {
            showLoader(true);
            let response = await axios.get('/download-acr/' + data?.id + `?${query}`, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const groupData = {
                completed: '1',
                acrDownloaded: '0'
            };
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            const url = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = `${data?.data}.xlsx`;

            a.click();

            window.URL.revokeObjectURL(url);
            a.remove();
            showLoader(false);
            if (data?.acrDownloaded.acrDownloaded === '0') {
                dispatch(getMainCategoryListing(groupData));
            }
            return Promise.resolve({ message: 'Excel file downloaded successfully' });
        } catch (err) {
            showLoader(false);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const AuctionClosingReportSlice = createSlice({
    name: 'AuctionClosingReportSlice',
    initialState: {}
});

export default AuctionClosingReportSlice.reducer;
