import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getBuyerType = createAsyncThunk('buyerTypeSlice/getBuyerType', async () => {
    try {
        let response = await axios.get('/buyer-type', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createBuyerType = createAsyncThunk('buyerTypeSlice/createBuyerType', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/buyer-type', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Buyer added successfully.');
        dispatch(getBuyerType());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateBuyerType = createAsyncThunk('buyerTypeSlice/updateBuyerType', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/buyer-type/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Buyer updated succesfully.');
        dispatch(getBuyerType());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteBuyerType = createAsyncThunk('buyerTypeSlice/deleteBuyerType', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/buyer-type/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Buyer deleted successfully.');
        dispatch(getBuyerType());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateBuyerTypeStatus = createAsyncThunk('buyerTypeSlice/updateBuyerTypeStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/buyer-type/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getBuyerType());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const buyerTypeSlice = createSlice({
    name: 'buyerTypeSlice',
    initialState: {
        data: [],
        option: []
    },
    extraReducers: (builder) => {
        builder.addCase(getBuyerType.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
    }
});

export default buyerTypeSlice.reducer;
