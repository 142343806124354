import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getCity = createAsyncThunk('citySlice/getCity', async () => {
    try {
        let response = await axios.get('/city', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getStateOption = createAsyncThunk('citySlice/getStateOption', async () => {
    try {
        let response = await axios.get('/state/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getPinCodeOption = createAsyncThunk('citySlice/getPinCodeOption', async (id) => {
    try {
        let response = await axios.get('/pincode/option?cityId=' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCityOption = createAsyncThunk('citySlice/getCityOption', async (id) => {
    try {
        let response = await axios.get('/city/options?stateId=' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createCity = createAsyncThunk('citySlice/createCity', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/city', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'City added succesfully.');
        await dispatch(getCity());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createPincode = createAsyncThunk('citySlice/createPincode', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/pincode', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Pincode added successfully.');
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateCity = createAsyncThunk('citySlice/updateCity', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/city/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'City updated successfully.');
        await dispatch(getCity());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updatePincode = createAsyncThunk('citySlice/updatePincode', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/pincode/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Pincode updated successfully.');
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteCity = createAsyncThunk('citySlice/deleteCity', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.delete('/city/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'City deleted successfully.');
        await dispatch(getCity());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deletePincode = createAsyncThunk('citySlice/deletePincode', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.delete('/pincode/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Pincode deleted successfully.');
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateCityStatus = createAsyncThunk('citySlice/updateCityStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/city/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'City updated successfully !');
        dispatch(getCity());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const citySlice = createSlice({
    name: 'citySlice',
    initialState: {
        data: [],
        option: [],
        cityOption: []
    },
    extraReducers: (builder) => {
        builder.addCase(getCity.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getStateOption.fulfilled, (state, action) => {
            state.option = action?.payload;
            return;
        });
        builder.addCase(getCityOption.fulfilled, (state, action) => {
            state.cityOption = action?.payload;
            return;
        });
        builder.addCase(getPinCodeOption.fulfilled, (state, action) => {
            state.pincodeOption = action?.payload;
            return;
        });
    }
});

export default citySlice.reducer;
