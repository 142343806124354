import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';
import queryString from 'query-string';

export const getBuyerTransaction = createAsyncThunk('buyerTransactionSlice/getBuyerTransaction', async (data) => {
    try {
        const query = queryString.stringify(data);

        let response = await axios.get('/buyer-transaction' + `?${query}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const buyerTransactionSlice = createSlice({
    name: 'buyerTransactionSlice',
    initialState: {
        data: []
    },

    extraReducers: (builder) => {
        builder.addCase(getBuyerTransaction.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
    }
});

export default buyerTransactionSlice.reducer;
