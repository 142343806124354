// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';
// ** API Imports
import axios from '@src/service/axios';

//** Toast Import
import { toast } from 'react-toastify';

export const getParticipationReport = createAsyncThunk('participationReportSlice/getParticipationReport', async (id) => {
    try {
        let response = await axios.get('/participation-report/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getLiveMainCategoryOption = createAsyncThunk('participationReportSlice/getLiveMainCategoryOption', async () => {
    try {
        let response = await axios.get('/live/main-category/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getSurpassedBasePrice = createAsyncThunk('participationReportSlice/getSurpassedBasePrice', async (id) => {
    try {
        let response = await axios.get('/participation-report/surpassed-base-price-vehicle/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getVehicleWith0Bids = createAsyncThunk('participationReportSlice/getVehicleWith0Bids', async (id) => {
    try {
        let response = await axios.get('/participation-report/no-bided-vehicle/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getLiveUsers = createAsyncThunk('participationReportSlice/getLiveUsers', async (id) => {
    try {
        let response = await axios.get('/participation-report/live-users/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getTopBuyer = createAsyncThunk('participationReportSlice/getTopBuyer', async (id) => {
    try {
        let response = await axios.get('/participation-report/top-buyer/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const participationReportSlice = createSlice({
    name: 'participationReportSlice',
    initialState: {
        participationReport: [],
        mainCategory: [],
        vehicleWith0Bids: [],
        liveUsers: [],
        top20Buyers: [],
        surpassedBasePrice: []
    },
    extraReducers: (builder) => {
        builder.addCase(getLiveMainCategoryOption.fulfilled, (state, action) => {
            state.mainCategory = action?.payload;
            return;
        });
        builder.addCase(getParticipationReport.fulfilled, (state, action) => {
            state.participationReport = action?.payload;
            return;
        });
        builder.addCase(getVehicleWith0Bids.fulfilled, (state, action) => {
            state.vehicleWith0Bids = action?.payload;
            return;
        });
        builder.addCase(getSurpassedBasePrice.fulfilled, (state, action) => {
            state.surpassedBasePrice = action?.payload;
            return;
        });
        builder.addCase(getLiveUsers.fulfilled, (state, action) => {
            state.liveUsers = action?.payload;
            return;
        });
        builder.addCase(getTopBuyer.fulfilled, (state, action) => {
            state.top20Buyers = action?.payload;
            return;
        });
    }
});

export default participationReportSlice.reducer;
