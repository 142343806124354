import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';
import queryString from 'query-string';

export const getACRDetails = createAsyncThunk('acrReportSlice/getACRDetails', async (data) => {
    const query = queryString.stringify(data);
    try {
        let response = await axios.get('/acr-downloaded/listing?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getACRDetailsWithFilter = createAsyncThunk('acrReportSlice/getACRDetailsWithFilter', async (params) => {
    let myStr = params;
    // replacing for % symbol
    myStr = String(myStr).replaceAll('%', '$*$');
    try {
        let response = await axios.get('/acr-downloaded/listing/with-filters' + myStr, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getACRDetailById = createAsyncThunk('mainCategorySlice/getACRDetailById', async (id) => {
    try {
        showLoader(true);
        let response = await axios.get('/acr/listing/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateAcrListingStatus = createAsyncThunk('mainCategorySlice/updateAcrListingStatus', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/acr/listing/status/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Status Change Successfully!');
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        showLoader(false);
        return Promise.reject(err);
    }
});

export const getLeadFollowUps = createAsyncThunk('acrReportSlice/getLeadFollowUps', async (data) => {
    const query = queryString.stringify(data);
    try {
        let response = await axios.get('/lead-follow-ups/listing?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getLeadFollowUpsWithFilter = createAsyncThunk('acrReportSlice/getLeadFollowUpsWithFilter', async (params) => {
    let myStr = params;
    // replacing for % symbol
    myStr = String(myStr).replaceAll('%', '$*$');
    try {
        let response = await axios.get('/lead-follow-ups/listing/with-filters' + myStr, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getClosedLeads = createAsyncThunk('acrReportSlice/getClosedLeads', async (data) => {
    const query = queryString.stringify(data);
    try {
        let response = await axios.post('/closed-lead/listing?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getCRMAllReport = createAsyncThunk('acrReportSlice/getCRMAllReport', async (data) => {
    const query = queryString.stringify(data);
    try {
        let response = await axios.get('/crm-all-report/listing?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getClosedLeadsWithFilter = createAsyncThunk('acrReportSlice/getClosedLeadsWithFilter', async (params) => {
    let myStr = params;
    // replacing for % symbol
    myStr = String(myStr).replaceAll('%', '$*$');
    try {
        let response = await axios.post('/closed-lead/listing/with-filters' + myStr, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const acrReportSlice = createSlice({
    name: 'acrReportSlice',
    initialState: {
        data: [],
        dataWithFiler: [],
        acrDetail: {},
        leadFollowUps: [],
        closedLeads: [],
        leadFollowUpsWithFilter: [],
        closedLeadsWithFilter: [],
        allReport: []
    },

    extraReducers: (builder) => {
        builder.addCase(getACRDetails.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getCRMAllReport.fulfilled, (state, action) => {
            state.allReport = action?.payload;
            return;
        });
        builder.addCase(getACRDetailsWithFilter.fulfilled, (state, action) => {
            state.dataWithFiler = action?.payload;
            return;
        });
        builder.addCase(getACRDetailById.fulfilled, (state, action) => {
            state.acrDetail = action?.payload;
            return;
        });
        builder.addCase(getLeadFollowUps.fulfilled, (state, action) => {
            state.leadFollowUps = action?.payload;
            return;
        });
        builder.addCase(getLeadFollowUpsWithFilter.fulfilled, (state, action) => {
            state.leadFollowUpsWithFilter = action?.payload;
            return;
        });
        builder.addCase(getClosedLeads.fulfilled, (state, action) => {
            state.closedLeads = action?.payload;
            return;
        });
        builder.addCase(getClosedLeadsWithFilter.fulfilled, (state, action) => {
            state.closedLeadsWithFilter = action?.payload;
            return;
        });
    }
});

export default acrReportSlice.reducer;
