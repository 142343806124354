// ** Reducers Imports
import navbar from './navbar';
import layout from './layout';
import auth from './authentication';
import permission from './permission';
import users from '../views/Setup/User/store';
import roles from '../views/Setup/RoleRights/Role/store';
import auditLog from '../views/Setup/AuditLog/store/index';
import moduleSlice from '../views/Setup/ModuleManage/store';
import category from '../views/Masters/VehicleCategory/store';
import subCategory from '../views/Masters/SubCategory/store';
import buyerType from '../views/Masters/BuyerType/store';
import buyer from '../views/BuyerAdministration/BuyerManagement/Tabs/store/index';
import state from '../views/Masters/StateMaster/store';
import city from '../views/Masters/CityMaster/store';
import mainCategory from '../views/Masters/MainCategory/store';
import seller from '../views/Masters/SellerMaster/store';
import listing from '../views/ManageListing/Listing/store';
import bidding from '../views/LiveAuctions/AllBids/store';
import tabs from './tabs';
import buyerTransaction from '../views/Reports/PassbookLedger/store';
import closingReport from '../views/Reports/AuctionClosingReport/store';
import acrReport from '../views/CRM/AddOfflineACR/store/index';
import helpDesk from '../views/Help/HelpDesk/store/index';
import dashboard from '../views/Dashboard/store/index';
import participationReport from '../views/BuyerAdministration/ParticipationReport/store/index';

const rootReducer = {
    auth,
    navbar,
    layout,
    permission,
    users,
    roles,
    moduleSlice,
    category,
    subCategory,
    buyerType,
    buyer,
    state,
    city,
    mainCategory,
    seller,
    listing,
    tabs,
    bidding,
    buyerTransaction,
    closingReport,
    acrReport,
    auditLog,
    helpDesk,
    dashboard,
    participationReport
};

export default rootReducer;
