import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getState = createAsyncThunk('stateSlice/getState', async () => {
    try {
        let response = await axios.get('/state', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getZoneOption = createAsyncThunk('stateSlice/getZoneOption', async () => {
    try {
        let response = await axios.get('/zone/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createState = createAsyncThunk('stateSlice/createState', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/state', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'State added successfully.');
        dispatch(getState());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateState = createAsyncThunk('stateSlice/updateState', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/state/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'State updated succesfully.');
        dispatch(getState());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteState = createAsyncThunk('stateSlice/deleteState', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/state/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'State deleted successfully.');
        dispatch(getState());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStateStatus = createAsyncThunk('stateSlice/updateStateStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/state/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getState());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const stateSlice = createSlice({
    name: 'stateSlice',
    initialState: {
        data: [],
        option: []
    },
    extraReducers: (builder) => {
        builder.addCase(getState.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getZoneOption.fulfilled, (state, action) => {
            state.option = action?.payload;
            return;
        });
    }
});

export default stateSlice.reducer;
