// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';

// ** ThemeConfig
import themeConfig from '../configs/themeConfig';

import SecureLS from 'secure-ls';

export const secureLs = new SecureLS({
    encodingType: 'aes',
    encryptionSecret: 'easymba-admin-panel',
    isCompression: false
});

const initialActiveTab = () => {
    const item = window.localStorage.getItem('activeTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.activeTab;
};

const initialOrderTab = () => {
    const item = window.localStorage.getItem('orderTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.orderTab;
};

const initialAssignTab = () => {
    const item = window.localStorage.getItem('initialAssignTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.initialAssignTab;
};
const initialRequestTab = () => {
    const item = window.localStorage.getItem('requestTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.requestTab;
};

const initialBiddingTab = () => {
    const item = window.localStorage.getItem('biddingTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.biddingTab;
};

const initialListingTab = () => {
    const item = window.localStorage.getItem('listingTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.listingTab;
};
const initialAcrTab = () => {
    const item = window.localStorage.getItem('acrTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.acrTab;
};
const initialProfileTab = () => {
    const item = window.localStorage.getItem('profileTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.profileTab;
};
const initialBuyerPermissionTab = () => {
    const item = window.localStorage.getItem('buyerPermissionTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.buyerPermissionTab;
};

export const tabSlice = createSlice({
    name: 'tabSliceData',
    initialState: {
        activeTab: initialActiveTab(),
        orderTab: initialOrderTab(),
        requestTab: initialRequestTab(),
        biddingTab: initialBiddingTab(),
        listingTab: initialListingTab(),
        acrTab: initialAcrTab(),
        profileTab: initialProfileTab(),
        assignTab: initialAssignTab(),
        buyerPermissionTab: initialBuyerPermissionTab()
    },
    reducers: {
        handleActiveTab: (state, action) => {
            state.activeTab = action.payload;
            localStorage.setItem('activeTab', JSON.stringify(action.payload));
        },

        removeTabs: (state, action) => {
            state.activeTab = '1';
            localStorage.setItem('activeTab', '"1"');
        },
        handleOrderTab: (state, action) => {
            state.orderTab = action.payload;
            localStorage.setItem('orderTab', JSON.stringify(action.payload));
        },
        handleAssignTab: (state, action) => {
            state.assignTab = action.payload;
            localStorage.setItem('assignTab', JSON.stringify(action.payload));
        },
        removeOrderTabs: (state, action) => {
            state.orderTab = '1';
            localStorage.setItem('orderTab', '"1"');
        },
        removeAssignTabs: (state, action) => {
            state.assignTab = '1';
            localStorage.setItem('assignTab', '"1"');
        },
        handleRequestTab: (state, action) => {
            state.requestTab = action.payload;
            localStorage.setItem('requestTab', JSON.stringify(action.payload));
        },
        removeRequestTabs: (state, action) => {
            state.requestTab = '1';
            localStorage.setItem('requestTab', '1');
        },
        handleBiddingTab: (state, action) => {
            state.biddingTab = action.payload;
            localStorage.setItem('biddingTab', JSON.stringify(action.payload));
        },
        removeBiddingTabs: (state, action) => {
            state.biddingTab = '1';
            localStorage.setItem('biddingTab', '1');
        },
        handleListingTab: (state, action) => {
            state.listingTab = action.payload;
            localStorage.setItem('listingTab', JSON.stringify(action.payload));
        },
        removeListingTabs: (state, action) => {
            state.listingTab = '1';
            localStorage.setItem('listingTab', '1');
        },
        handleAcrTab: (state, action) => {
            state.acrTab = action.payload;
            localStorage.setItem('acrTab', JSON.stringify(action.payload));
        },
        removeAcrTabs: (state, action) => {
            state.acrTab = '1';
            localStorage.setItem('acrTab', '1');
        },
        handleProfileTab: (state, action) => {
            state.profileTab = action.payload;
            localStorage.setItem('profileTab', JSON.stringify(action.payload));
        },
        removeProfileTabs: (state, action) => {
            state.profileTab = '1';
            localStorage.setItem('profileTab', '1');
        },
        handleBuyerPermissionTab: (state, action) => {
            state.buyerPermissionTab = action.payload;
            localStorage.setItem('buyerPermissionTab', JSON.stringify(action.payload));
        },
        removeBuyerPermissionTabs: (state, action) => {
            state.buyerPermissionTab = '1';
            localStorage.setItem('buyerPermissionTab', '1');
        }
    }
});

export const {
    handleActiveTab,
    removeTabs,
    handleOrderTab,
    removeOrderTabs,
    handleRequestTab,
    removeRequestTabs,
    handleBiddingTab,
    removeBiddingTabs,
    handleListingTab,
    removeListingTabs,
    handleAcrTab,
    removeAcrTabs,
    handleProfileTab,
    removeProfileTabs,
    handleAssignTab,
    removeAssignTabs,
    handleBuyerPermissionTab,
    removeBuyerPermissionTabs
} = tabSlice.actions;

export default tabSlice.reducer;
