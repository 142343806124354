import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';
// Loader Components
import showLoader from '@components/loader';

export const getTopBidding = createAsyncThunk('biddingSlice/getTopBidding', async (data) => {
    const query = queryString.stringify(data?.data);
    try {
        let response = await axios.get('/bidding/listing/' + data?.id + `?${query}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteBidding = createAsyncThunk('biddingSlice/deleteBidding', async (deleteId) => {
    try {
        let response = await axios.delete('/bidding/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Bidding deleted successfully');
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const biddingSlice = createSlice({
    name: 'biddingSlice',
    initialState: {
        topBidding: []
    },
    reducers: {
        removeTopBidding: (state) => {
            state.topBidding = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTopBidding.fulfilled, (state, action) => {
            state.topBidding = action?.payload;
            return;
        });
    }
});

export const { removeTopBidding } = biddingSlice.actions;

export default biddingSlice.reducer;
