import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';
import queryString from 'query-string';

export const getListing = createAsyncThunk('listingSlice/getListing', async () => {
    try {
        let response = await axios.get('/listing', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getListingById = createAsyncThunk('listingSlice/getListingById', async (id) => {
    try {
        let response = await axios.get('/listing/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getListingImagesById = createAsyncThunk('listingSlice/getListingImagesById', async (id) => {
    try {
        let response = await axios.get('/listing/image/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getListingOption = createAsyncThunk('listingSlice/getListingOption', async () => {
    try {
        let response = await axios.get('/listing/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getListingWithQuery = createAsyncThunk('listingSlice/getListingWithQuery', async (data) => {
    try {
        const query = queryString.stringify(data);

        let response = await axios.get('/listing/bulk-edit' + `?${query}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createListing = createAsyncThunk('listingSlice/createListing', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/listing', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Listing added successfully.');
        dispatch(getMainCategoryListing({ completed: '0' }));
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createBulkImageListing = createAsyncThunk('listingSlice/createBulkImageListing', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/listing/bulk-image/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Listing added successfully.');
        dispatch(getListing());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const bulkUpdate = createAsyncThunk('listingSlice/bulkUpdate', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/listing/bulk-update', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Listing updated succesfully.');
        dispatch(getListing());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateListing = createAsyncThunk('listingSlice/updateListing', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/listing/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Listing updated succesfully.');
        dispatch(getListing());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteListing = createAsyncThunk('listingSlice/deleteListing', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/listing/' + data?.deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Listing deleted successfully.');
        dispatch(getMainCategoryByListing({ id: data?.id, data: { completed: '0' } }));
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteAuctionImages = createAsyncThunk('listingSlice/deleteAuctionImages', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/images/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Images deleted successfully.');
        dispatch(getListing());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateListingStatus = createAsyncThunk('listingSlice/updateListingStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/listing/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getListing());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// get main category listing
export const getMainCategoryListing = createAsyncThunk('listingSlice/getMainCategoryListing', async (data) => {
    try {
        const query = queryString.stringify(data);

        let response = await axios.get('/listing-group' + `?${query}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getMainCategoryByListing = createAsyncThunk('listingSlice/getMainCategoryByListing', async (data) => {
    try {
        const query = queryString.stringify(data?.data);

        let response = await axios.get('/listing-group/' + data?.id + `?${query}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateMainCategoryListing = createAsyncThunk('listingSlice/updateMainCategoryListing', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/listing-group/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Main Category Listing updated successfully.');
        dispatch(getMainCategoryListing({ completed: '0' }));
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteListingGroup = createAsyncThunk('listingSlice/deleteListingGroup', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.delete('/listing-group/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Listing deleted successfully.');
        dispatch(getMainCategoryListing({ completed: '0' }));
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateListingGroup = createAsyncThunk('listingSlice/updateListingGroup', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/add-listing/listing-group/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Main Category ListingGroup updated successfully.');
        dispatch(getMainCategoryByListing({ id: data?.id, data: { completed: '0' } }));
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createSaleFileListing = createAsyncThunk('listingSlice/createSaleFileListing', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/upload/listing-doc/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Listing added successfully.');
        dispatch(getMainCategoryListing({ completed: '0' }));
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const listingSlice = createSlice({
    name: 'listingSlice',
    initialState: {
        data: [],
        option: [],
        listingData: [],
        mainCategoryListing: []
    },
    reducers: {
        removeListingData: (state, action) => {
            state.listingData = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getListing.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getListingOption.fulfilled, (state, action) => {
            state.option = action?.payload;
            return;
        });
        builder.addCase(getMainCategoryByListing.fulfilled, (state, action) => {
            state.listingData = action?.payload;
            return;
        });
        builder.addCase(getMainCategoryListing.fulfilled, (state, action) => {
            state.mainCategoryListing = action?.payload;
            return;
        });
    }
});

export const { removeListingData } = listingSlice.actions;

export default listingSlice.reducer;
